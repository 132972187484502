const styles = {
  container:
    'p-6 sm:p-10 bg-secondary-50 rounded-lg font-sans flex flex-col gap-y-4',

  body: '',
  foot: {
    container: 'flex flex-row items-center gap-x-4',

    head: '',
    body: 'flex flex-col items-start gap-y-0',

    title: 'text-base font-semibold',
    description: 'text-sm font-light',
  },
};

export default styles;
