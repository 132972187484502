'use client';

import React from 'react';
import { Masonry } from 'react-plock';
import { APIResponseCollection } from 'types/models';
import { TNextClientComponent } from 'types/next';
import ReviewItem from '../ReviewItem';
type TProps = {
  reviews: APIResponseCollection<'api::review.review'>;
};
const ReviewsMasonry: TNextClientComponent<TProps> = props => {
  const {
    reviews
  } = props;
  return <Masonry items={reviews.data} config={{
    columns: [1, 2, 3],
    gap: [12, 24, 24],
    media: [640, 1280, 1280]
  }} render={review => <ReviewItem key={review.id} review={review} />} data-sentry-element="Masonry" data-sentry-component="ReviewsMasonry" data-sentry-source-file="ReviewsMasonry.tsx" />;
};
export default ReviewsMasonry;