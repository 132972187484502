import { toClassName } from 'helpers/format';

const text = 'font-sans';
const paragraph =
  'font-light leading-relaxed sm:leading-loose pb-2 sm:pb-4 text-sm sm:text-base';
const heading = 'font-bold';
const link = 'text-primary-500';

const styles = {
  block: {
    paragraph: toClassName(text, paragraph),
    h1: toClassName(text, heading, 'text-4xl'),
    h2: toClassName(text, heading, 'text-3xl'),
    h3: toClassName(text, heading, 'text-2xl'),
    h4: toClassName(text, heading, 'text-xl'),
    h5: toClassName(text, heading, 'text-lg'),
    h6: toClassName(text, heading, 'text-md'),
    list: '',
    quote: '',
    code: '',
    image: '',
    link: toClassName(text, paragraph, link),
  },

  modifier: {
    bold: '',
    italic: '',
    underline: '',
    strikethrough: '',
    code: '',
  },
};

export default styles;
